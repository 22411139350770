<template>
  <v-container fluid>
    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-16 mb-6">Empresas</h1>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
              <v-row>
                <v-dialog v-model="dialogNuevaEmpresa" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="$store.state.usuario.rol !== 'lectura_mtto'"
                      v-bind="attrs"
                      v-on="on"
                      rounded
                      color="primary"
                      small
                      dark
                      >Nueva Empresa</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="razonSocial"
                              label="Razon Social"
                            ></v-text-field>
                            <div v-if="submited && !$v.razonSocial.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo Razon Social es requerido
                              </v-alert>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="rfc" label="RFC"></v-text-field>
                            <div v-if="submited && !$v.rfc.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo RFC es requerido
                              </v-alert>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="nombreComercial"
                              label="Nombre Comercial"
                            ></v-text-field>
                            <div v-if="submited && !$v.nombreComercial.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo Nombre Comercial es requerido
                              </v-alert>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="representante"
                              label="Representante"
                              hint="Nombre del representante asignado a la empresa"
                            ></v-text-field>
                            <div v-if="submited && !$v.representante.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo Representante es requerido
                              </v-alert>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="cerrarModalEmpresa()">
                        Cerrar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="guardar()">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="empresas"
              :search="search"
              item-key="nombreComercial"
            >
              <template
                v-if="$store.state.usuario.rol !== 'lectura_mtto'"
                v-slot:[`item.action`]="{ item }"
              >
                <v-icon @click="editItem(item)" small class="mr-2"
                  >mdi-border-color</v-icon
                >
                <template>
                  <v-icon class="mr-2">mdi-delete</v-icon>
                </template>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  validations: {
    razonSocial: {
      required,
    },

    rfc: {
      required,
    },
    nombreComercial: {
      required,
    },
    representante: {
      required,
    },
  },
  data() {
    return {
      headers: [
        { text: "Actions", value: "action", sortable: false },
        { text: "Razon Social", value: "razonSocial", sortable: true },
        { text: "RFC", value: "rfc", sortable: true },
        { text: "Nombre Comercial", value: "nombreComercial", sortable: true },
        { text: "Representante", value: "representante", sortable: true },
      ],
      empresas: [],
      search: "",
      dialogNuevaEmpresa: false,
      razonSocial: "",
      rfc: "",
      editedIndex: -1,
      nombreComercial: "",
      representante: "",
      submited: "",
      alert: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Empresa" : "Editar Empresa";
    },
  },
  created() {
    this.listar();
  },
  methods: {
    listar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/empresa/list/", configuracion)
        .then(function (response) {
          me.empresas = response.data;
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    limpiar() {
      (this.rfc = ""),
        (this.razonSocial = ""),
        (this.nombreComercial = ""),
        (this.representante = "");
      this.editedIndex = -1;
    },
    guardar() {
      let me = this;
      if (this.editedIndex > -1) {
        axios
          .put("/empresa/update", {
            _id: this._id,
            razonSocial: this.razonSocial,
            rfc: this.rfc,
            nombreComercial: this.nombreComercial,
            representante: this.representante,
          })
          .then(function (response) {
            console.log(response);
            me.listar();
            me.dialogNuevaEmpresa = false;
            me.addSuccessNotification("Empresa actualizada");
            me.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.submited = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          me.addErrorNotification();
          return false;
        }
        axios
          .post("/empresa/add", {
            razonSocial: this.razonSocial,
            rfc: this.rfc,
            nombreComercial: this.nombreComercial,
            representante: this.representante,
          })
          .then(function (response) {
            console.log(response);
            me.listar();
            me.dialogNuevaEmpresa = false;
            me.addSuccessNotification("Empresa agregada");
            me.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    editItem(item) {
      this._id = item._id;
      this.rfc = item.rfc;
      this.razonSocial = item.razonSocial;
      this.representante = item.representante;
      this.nombreComercial = item.nombreComercial;
      this.editedIndex = 1;

      this.dialogNuevaEmpresa = true;
    },
    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    cerrarModalEmpresa() {
      this.dialogNuevaEmpresa = false;
      this.limpiar();
      this.editedIndex = -1;
    },
  },
};
</script>
