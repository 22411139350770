<template>
  <v-container fluid>
    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-16 mb-6">Personal</h1>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
              <v-row>
                <v-dialog v-model="dialogNuevoPersonal" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="$store.state.usuario.rol !== 'lectura_mtto'"
                      v-bind="attrs"
                      v-on="on"
                      rounded
                      color="primary"
                      small
                      dark
                      >Nuevo Personal</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="nombreCompleto"
                              label="Nombre Completo"
                            ></v-text-field>
                            <div v-if="submited && !$v.nombreCompleto.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo Nombre es requerido
                              </v-alert>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-on:change="filterEmpresa()"
                              :items="empresas"
                              v-model="empresa"
                              label="Empresa"
                            ></v-autocomplete>
                            <div v-if="submited && !$v.empresa.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo Empresa es requerido
                              </v-alert>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              :items="departamentos"
                              v-model="departamento"
                              label="Departamento"
                            ></v-autocomplete>
                            <div v-if="submited && !$v.departamento.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo Departamento es requerido
                              </v-alert>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="telefono"
                              label="Telefono"
                            ></v-text-field>
                            <div v-if="submited && !$v.telefono.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo Telefono es requerido
                              </v-alert>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="correo" label="Correo"></v-text-field>
                            <div v-if="submited && !$v.correo.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo correo es requerido
                              </v-alert>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="cerrarModalPersonal()">
                        Cerrar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="guardar()">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="personales"
              :search="search"
              item-key="nombreCompleto"
            >
              <template
                v-if="$store.state.usuario.rol !== 'lectura_mtto'"
                v-slot:[`item.action`]="{ item }"
              >
                <v-icon @click="editItem(item)" small class="mr-2"
                  >mdi-border-color</v-icon
                >
                <template>
                  <v-icon class="mr-2">mdi-delete</v-icon>
                </template>
              </template>
              <template v-slot:[`item.estatus`]="{ item }">
                <v-chip small v-if="item.estado == 0" color="green" text-color="white">
                  Activo
                </v-chip>
                <v-chip small v-if="item.estado == 1" color="red" text-color="white">
                  Baja
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  validations: {
    nombreCompleto: {
      required,
    },
    empresa: {
      required,
    },
    departamento: {
      required,
    },
    telefono: {
      required,
    },
    correo: {
      required,
    },
  },
  data() {
    return {
      headers: [
        { text: "Actions", value: "action", sortable: false },
        { text: "Personal", value: "nombreCompleto", sortable: true },
        { text: "Telefono", value: "telefono", sortable: true },
        { text: "Correo", value: "correo", sortable: true },
        { text: "Empresa", value: "empresa.nombreComercial", sortable: true },
        { text: "Ubicacion", value: "departamento.ubicacion.ubicacion", sortable: true },
        { text: "Correo", value: "correo", sortable: true },
        { text: "Estatus", value: "estatus", sortable: true },
      ],
      departamentos: [],
      departamento: "",
      empresas: [],
      empresa: "",
      empresaSeleccionada: "",
      nombreCompleto: "",
      telefono: "",
      search: "",
      correo: "",
      personales: [],
      dialogNuevoPersonal: false,
      ubicaciones: [],
      editedIndex: -1,
      submited: "",
      alert: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Personal" : "Editar Dersonal";
    },
  },
  created() {
    this.listar();
    this.selectEmpresas();
  },
  methods: {
    async listar() {
      let me = this;
      let empresaUsuario = this.$store.state.usuario.personal.empresa;
      let usuario = this.$store.state.usuario.rol;

      await axios
        .get("/personal/list")
        .then(function (response) {
          if (usuario == "capturista") {
            me.personales = [];
            response.data.forEach((e) => {
              if (e.empresa._id == empresaUsuario) {
                me.personales.push(e);
              }
            });
            me.empresas.push({
              text: me.personales[0].empresa.nombreComercial,
              value: me.personales[0].empresa._id,
            });
          } else {
            me.personales = response.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    limpiar() {
      (this.empresa = ""), (this.departamento = "");
      this.nombreCompleto = "";
      (this.telefono = ""), (this.correo = "");
      this.alert = false;
    },
    guardar() {
      let me = this;
      if (this.editedIndex > -1) {
        axios
          .put("/personal/update", {
            _id: this._id,
            nombreCompleto: this.nombreCompleto,
            empresa: this.empresa,
            departamento: this.departamento,
            telefono: this.telefono,
            correo: this.correo,
          })
          .then(function (response) {
            console.log(response);
            me.editedIndex = -1;
            me.listar();
            me.alert = true;
            me.dialogNuevoPersonal = false;
            me.addSuccessNotification("Personal actualizado");
            me.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.submited = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          me.addErrorNotification();
          return false;
        }
        axios
          .post("/personal/add", {
            empresa: this.empresa,
            departamento: this.departamento,
            nombreCompleto: this.nombreCompleto,
            telefono: this.telefono,
            correo: this.correo,
          })
          .then(function (response) {
            console.log(response);
            me.listar();
            me.alert = true;
            me.dialogNuevoPersonal = false;
            me.addSuccessNotification("Personal agregada");
            me.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    editItem(item) {
      this._id = item._id;
      this.empresa = item.empresa._id;
      this.selectEmpresa(item.empresa._id);
      this.departamento = item.departamento._id;
      this.nombreCompleto = item.nombreCompleto;
      this.telefono = item.telefono;
      this.correo = item.correo;
      this.editedIndex = 1;

      this.dialogNuevoPersonal = true;
    },

    selectEmpresas() {
      let me = this;
      let empresaArray = [];
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/empresa/list", configuracion)
        .then((response) => {
          empresaArray = response.data;
          empresaArray.map(function (x) {
            me.empresas.push({ text: x.nombreComercial, value: x._id });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    filterEmpresa() {
      this.departamentos = [];
      this.empresaSeleccionada = this.empresa;
      this.selectEmpresa(this.empresaSeleccionada);
    },

    selectEmpresa(empresa) {
      let me = this;
      let departamentoArrya = [];
      axios
        .get("/departamentos/queryEmpresa?empresa=" + empresa)
        .then((response) => {
          departamentoArrya = response.data;
          departamentoArrya.map(function (x) {
            me.departamentos.push({
              text: x.departamento + " " + x.ubicacion.ubicacion,
              value: x._id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    cerrarModalPersonal() {
      this.dialogNuevoPersonal = false;
      this.limpiar();
      this.editedIndex = -1;
    },
  },
};
</script>
