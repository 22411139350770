import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import Layout from "@/components/Layout/Layout";

// Pages
import Dashboard from "@/pages/Dashboard/Dashboard";
import Contrato from "@/pages/Costeo/Contrato.vue";
//import Maps from '@/pages/Maps/Google'
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";
import Empresa from "@/pages/Catalogos/Empresa";
import Ubicaciones from "@/pages/Catalogos/Ubicacion";
import Proveedor from "@/pages/Catalogos/Proveedor";
import Departamentos from "@/pages/Catalogos/Departamentos";
import Personal from "@/pages/Catalogos/Personal";
import Arrendadora from "@/pages/Catalogos/Arrendadora";
import Activos from "@/pages/Activos/Activos";
import Resguardos from "@/pages/Activos/Resguardos";
import Importar from "@/pages/Admin/Importar";
import Usuarios from "@/pages/Catalogos/Usuarios";
import Mantenimientos from "@/pages/Activos/Mantenimientos";
import Costeo from "@/pages/Costeo/Costeo";
import Partidas from "@/pages/Catalogos/Partidas";
import ProgramasPress from "@/pages/Catalogos/ProgramasPres";
import Trazabilidad from "@/pages/Costeo/Trazabilidad";
import Teb from "@/pages/Costeo/Teb";
import OS from "@/pages/Catalogos/OrdenesServicio.vue";
import MttoPreventivo from "@/pages/Mtto/MttoPreventivo.vue";
import MttoEquipos from "@/pages/Mtto/MttoEquipos.vue";
import ReporteFallas from "@/pages/Mtto/ReporteFallas.vue";
import Mecanicos from "@/pages/Mtto/Mecanicos.vue";
import SeguimientoFallas from "@/pages/Mtto/SeguimientoFallas.vue";
import PantallasReportes from "@/pages/Mtto/PantallaReportes.vue";
import CosteoProrrateado from "@/pages/Costeo/CosteoProrrateado";
import Estimaciones from "@/pages/Costeo/Estimacion.vue";
import Tables from "@/pages/Tables/Basic.vue";
import AMEF from "@/pages/Mtto/AMEF.vue";
import Horometro from "@/pages/Mtto/Horometro";
import ReporteFallaLista from "@/pages/Mtto/ReporteFallasFilter";
import EquipoEstatus from "@/pages/Mtto/EquiposEstatus";
import DashboardCosteo from "@/pages/Dashboard/DashboardCosteo";
import DashboardMtto from "@/pages/Dashboard/DashboardMtto";

import TrazabilidadTest from "@/pages/Costeo/TrazabilidadTest";
import ReporteFallasMtto from "@/pages/Mtto/ReporteFallasMtto";
import OrdenesServicioMtto from "@/pages/Mtto/OrdenServicio";
import OrdenSalidaEquipo from "@/pages/Activos/OrdenSalida";
import TrabajosMantenimiento from "@/pages/Dashboard/TrabajosMtto";
import HistorialProrrateo from "@/pages/Costeo/HistorialProrrateo";

import Scan from "@/pages/Activos/Scan";

Vue.use(Router);

var router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "login",
      name: "Layout",
      component: Layout,

      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: {
            root: true,
            usuario: true,
            pemex: true,
            mtto: true,
            activos: true,
            jefe_mtto: true,
            mecanico: true,
            lectura_mtto: true,
          },
        },

        {
          path: "/login",
          name: "login",
          component: Login,
          meta: {
            libre: true,
          },
        },

        {
          path: "tables",
          name: "Tables",
          component: Tables,
          meta: {
            root: true,
          },
        },
        {
          path: "empresa",
          name: "Empresa",
          component: Empresa,
          meta: {
            root: true,
            lectura_mtto: true,
          },
        },
        {
          path: "ubicacion",
          name: "Ubicacion",
          component: Ubicaciones,
          meta: {
            root: true,
            activos: true,
            lectura_mtto: true,

            usuario: true,
          },
        },
        {
          path: "proveedor",
          name: "Proveedor",
          component: Proveedor,
          meta: {
            root: true,
          },
        },
        {
          path: "departamentos",
          name: "Departamentos",
          component: Departamentos,
          meta: {
            root: true,
            activos: true,
            lectura_mtto: true,

            usuario: true,
          },
        },
        {
          path: "personal",
          name: "Personal",
          component: Personal,
          meta: {
            root: true,
            activos: true,
            lectura_mtto: true,

            usuario: true,
          },
        },
        {
          path: "arrendadora",
          name: "Arrendadora",
          component: Arrendadora,
          meta: {
            root: true,
          },
        },
        {
          path: "activos",
          name: "Activos",
          component: Activos,
          meta: {
            root: true,
            usuario: true,
            capturista: true,
            activos: true,
            jefe_mtto: true,
            lectura_mtto: true,
          },
        },
        {
          path: "ose",
          name: "Ose",
          component: OrdenSalidaEquipo,
          meta: {
            root: true,

            activos: true,
          },
        },
        {
          path: "scan",
          name: "Scan",
          component: Scan,
          meta: {
            root: true,
            usuario: true,
            capturista: true,
            activos: true,
          },
        },
        {
          path: "resguardos",
          name: "Resguardos",
          component: Resguardos,
          meta: {
            root: true,
            activos: true,
            usuario: true,
          },
        },
        {
          path: "mantenimientos",
          name: "Mantenimientos",
          component: Mantenimientos,
          meta: {
            root: true,
            lectura_mtto: true,
          },
        },
        {
          path: "importar",
          name: "Importar",
          component: Importar,
          meta: {
            root: true,
          },
        },
        {
          path: "usuarios",
          name: "Usuarios",
          component: Usuarios,
          meta: {
            root: true,
          },
        },

        {
          path: "costeo",
          name: "Costeo",
          component: Costeo,
          meta: {
            root: true,
            pemex: true,
          },
        },
        {
          path: "partidas",
          name: "Partidas",
          component: Partidas,
          meta: {
            root: true,
            pemex: true,
          },
        },
        {
          path: "programasPress",
          name: "ProgramasPress",
          component: ProgramasPress,
          meta: {
            root: true,
            pemex: true,
          },
        },
        {
          path: "dashboard_costeo",
          name: "DashboardCosteo",
          component: DashboardCosteo,
          meta: {
            root: true,
            pemex: true,
          },
        },
        {
          path: "dashboard_mtto",
          name: "DashboardMtto",
          component: DashboardMtto,
          meta: {
            root: true,
            jefe_mtto: true,
            mtto: true,
            lectura_mtto: true,
          },
        },
        {
          path: "trabajos_mtto",
          name: "TrabajosMtto",
          component: TrabajosMantenimiento,
          meta: {
            root: true,
            jefe_mtto: true,
            mtto: true,
            lectura_mtto: true,
          },
        },
        {
          path: "trazabilidad",
          name: "Trazabilidad",
          component: Trazabilidad,
          meta: {
            root: true,
            pemex: true,
          },
        },
        {
          path: "teb",
          name: "Teb",
          component: Teb,
          meta: {
            root: true,
            pemex: true,
          },
        },
        {
          path: "os",
          name: "OS",
          component: OS,
          meta: {
            root: true,
            pemex: true,
          },
        },
        {
          path: "costeo_prorrateado",
          name: "CosteoProrrateado",
          component: CosteoProrrateado,
          meta: {
            root: true,
            pemex: true,
          },
        },
        {
          path: "historial_prorrateo",
          name: "historial_prorrateo",
          component: HistorialProrrateo,
          meta: {
            root: true,
            pemex: true,
          },
        },
        {
          path: "estimaciones",
          name: "Estimaciones",
          component: Estimaciones,
          meta: {
            root: true,
            pemex: true,
          },
        },
        {
          path: "contrato",
          name: "Contrato",
          component: Contrato,
          meta: {
            root: true,
            pemex: true,
          },
        },
        //MTTO
        {
          path: "mtto_preventivo",
          name: "Preventivo",
          component: MttoPreventivo,
          meta: {
            root: true,
            mtto: true,
          },
        },
        {
          path: "mtto_equipo",
          name: "Equipos",
          component: MttoEquipos,
          meta: {
            root: true,
            mtto: true,
            jefe_mtto: true,
            lectura_mtto: true,
          },
        },
        {
          path: "equipo_estatus",
          name: "EquiposEstatus",
          component: EquipoEstatus,
          meta: {
            root: true,
            mtto: true,
            jefe_mtto: true,
            lectura_mtto: true,
          },
        },
        {
          path: "reporte_fallas",
          name: "reporte_fallas",
          component: ReporteFallas,
          meta: {
            root: true,
            mtto: true,
            jefe_mtto: true,
            lectura_mtto: true,
          },
        },
        {
          path: "mecanicos",
          name: "mecanicos",
          component: Mecanicos,
          meta: {
            jefe_mtto: true,
            root: true,
            lectura_mtto: true,
          },
        },
        {
          path: "seguimiento_fallas",
          name: "seguimiento_fallas",
          component: SeguimientoFallas,
          meta: {
            mecanico: true,
            root: true,
            lectura_mtto: true,
          },
        },
        {
          path: "pantalla_reportes",
          name: "pantalla_reportes",
          component: PantallasReportes,
          meta: {
            mecanico: true,
            root: true,
          },
        },
        {
          path: "amef",
          name: "amef",
          component: AMEF,
          meta: {
            root: true,
          },
        },
        {
          path: "horometro",
          name: "horometro",
          component: Horometro,
          meta: {
            root: true,
          },
        },
        {
          path: "reporte_fallas_lista",
          name: "reporte_fallas_lista",
          component: ReporteFallaLista,
          meta: {
            root: true,
            jefe_mtto: true,
            mtto: true,
            lectura_mtto: true,
          },
        },
        {
          path: "reporte_fallas_mtto",
          name: "reporte_fallas_mtto",
          component: ReporteFallasMtto,
          meta: {
            root: true,
            jefe_mtto: true,
            lectura_mtto: true,
          },
        },
        {
          path: "trazabilidadTest",
          name: "trazabilidadTest",
          component: TrazabilidadTest,
          meta: {
            root: true,
          },
        },
        {
          path: "os_mtto",
          name: "os_mtto",
          component: OrdenesServicioMtto,
          meta: {
            root: true,
            jefe_mtto: true,
            lectura_mtto: true,
          },
        },
      ],
    },
    {
      path: "*",
      name: "Error",
      component: Error,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.libre)) {
    next();
  } else if (store.state.usuario && store.state.usuario.rol == "root") {
    if (to.matched.some((record) => record.meta.root)) {
      next();
    }
  } else if (store.state.usuario && store.state.usuario.rol == "usuario") {
    if (to.matched.some((record) => record.meta.usuario)) {
      next();
    }
  } else if (store.state.usuario && store.state.usuario.rol == "capturista") {
    if (to.matched.some((record) => record.meta.usuario)) {
      next();
    }
  } else if (store.state.usuario && store.state.usuario.rol == "pemex") {
    if (to.matched.some((record) => record.meta.pemex)) {
      next();
    }
  } else if (store.state.usuario && store.state.usuario.rol == "mtto") {
    if (to.matched.some((record) => record.meta.mtto)) {
      next();
    }
  } else if (store.state.usuario && store.state.usuario.rol == "activos") {
    if (to.matched.some((record) => record.meta.activos)) {
      next();
    }
  } else if (store.state.usuario && store.state.usuario.rol == "lectura_mtto") {
    if (to.matched.some((record) => record.meta.lectura_mtto)) {
      next();
    }
  } else if (store.state.usuario && store.state.usuario.rol == "jefe_mtto") {
    if (to.matched.some((record) => record.meta.jefe_mtto)) {
      next();
    }
  } else if (store.state.usuario && store.state.usuario.rol == "mecanico") {
    if (to.matched.some((record) => record.meta.mecanico)) {
      next();
    }
  } else {
    next({ name: "login" });
  }
});

export default router;
