<template>
  <div>
    <DashboardMtto v-if="esRoot || esMtto || esJefeMtto || esLecturaMtto" />
    <DashboardActivos v-if="esRoot || esActivosFijos || esLecturaMtto" />
  </div>
</template>

<script>
import DashboardActivos from "../Dashboard/DashboardActivos.vue";
import DashboardMtto from "../Dashboard/DashboardMtto.vue";
export default {
  components: {
    DashboardActivos,
    DashboardMtto,
  },

  computed: {
    esRoot() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "root";
    },
    esUsuario() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "usuario";
    },
    esCapturista() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "capturista";
    },
    esActivosFijos() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "activos";
    },
    esJefeMtto() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "jefe_mtto";
    },
    esMtto() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "mtto";
    },
    esLecturaMtto() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "lectura_mtto";
    },
  },
};
</script>

<style lang="scss" scoped></style>
