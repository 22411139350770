<template>
  <v-container fluid>
    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-11 mb-6">Mecanicos</h1>
    </div>
    <v-card class="mx-auto">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Lista de Mecanicos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.state.usuario.rol !== 'lectura_mtto'"
          icon
          @click="mostrarNuevo()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-list v-model="selectedItem" link subheader two-line>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="mecanico in mecanicos" :key="mecanico._id">
            <v-list-item-avatar v-if="mecanico.estatus == 'Activo'">
              <v-icon class="success" dark> mdi-account-hard-hat </v-icon>
            </v-list-item-avatar>
            <v-list-item-avatar v-if="mecanico.estatus == 'Inactivo'">
              <v-icon class="red" dark> mdi-account-hard-hat </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ mecanico.nombreMecanico }}</v-list-item-title>
              <v-list-item-subtitle>{{ mecanico.categoria }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" color="primary" class="mb-2">
                    <v-icon> mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-if="mecanico.estatus == 'Activo'"
                    @click="editItem(mecanico)"
                  >
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mecanico.estatus == 'Activo'"
                    @click="mostrarDialogBaja(mecanico._id)"
                  >
                    <v-list-item-title>Dar de baja</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mecanico.estatus == 'Inactivo'"
                    @click="mostrarDialogAlta(mecanico._id)"
                  >
                    <v-list-item-title>Reactivar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-sheet>
      <v-navigation-drawer v-model="drawer" app clipped inset persistent right>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><span>
                <h4>Nuevo Mecanico</h4>
              </span></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <div class="spacing-playground pa-3">
          <p class="font-weight-light" style="margin-bottom: -10px">
            Nombre del Mecanico
          </p>
          <v-text-field v-model="nombreMecanico"></v-text-field>
          <p class="font-weight-light" style="margin-bottom: -10px">Categoria</p>
          <div>
            <v-text-field
              v-model="categoriaMecanico"
              style="margin-bottom: -10px"
            ></v-text-field>
          </div>
          <br />
          <v-btn class="mx-2" @click="salir()" dark small color="black">
            <v-icon dark> mdi-exit-to-app </v-icon>Salir
          </v-btn>
          <v-btn
            class="mx-2"
            v-if="editedIndex == false"
            @click="guardar()"
            dark
            small
            color="black"
          >
            <v-icon dark> mdi-content-save </v-icon>Guardar
          </v-btn>
          <v-btn
            class="mx-2"
            v-if="editedIndex == true && $store.state.usuario.rol !== 'lectura_mtto'"
            @click="guardar()"
            dark
            small
            color="black"
          >
            <v-icon dark> mdi-update </v-icon>Editar
          </v-btn>
        </div>
      </v-navigation-drawer>
    </v-sheet>
    <v-dialog v-model="dialogMecanicoBaja" persistent max-width="490">
      <v-card>
        <v-card-title class="text-h5"> ¿Desactivar al mecanico? </v-card-title>
        <v-card-text>Seguro que desea dar de baja a este mecanico?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogMecanicoBaja = false">
            Salir
          </v-btn>
          <v-btn
            @click="deactivate"
            v-if="$store.state.usuario.rol !== 'lectura_mtto'"
            color="green darken-1"
            text
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMecanicoAlta" persistent max-width="490">
      <v-card>
        <v-card-title class="text-h5"> ¿Habilitar al mecanico? </v-card-title>
        <v-card-text>Seguro que desea dar de alta a este mecanico?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogMecanicoAlta = false">
            Salir
          </v-btn>
          <v-btn
            @click="activate"
            v-if="$store.state.usuario.rol !== 'lectura_mtto'"
            color="green darken-1"
            text
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  name: "app",

  data() {
    return {
      nombreMecanico: "",
      categoriaMecanico: "",
      dialogGenerarFallas: false,
      e1: 1,
      moment,
      drawer: false,
      mostrarDetalleDeActivo: false,
      activoPorQuery: [],
      cycle: true,
      id: "",
      dialogCancelar: false,
      fechaFalla: "",
      buscarEquipo: "",
      buscar: "",
      otraFalla: "",
      editedIndex: false,
      descripcion: "",
      operador: "",
      mecanicos: [],
      reportes: [],
      motivo_cancelacion: "",
      datosReporteFalla: [],
      mostrarMecanico: false,
      dialogMecanicoBaja: false,
      dialogMecanicoAlta: false,
      mecanicosActivos: [],
      selectedItem: null,
    };
  },
  computed: {},
  created() {
    this.listar();
  },
  methods: {
    limpiar() {
      this.nombreMecanico = "";
      this.categoriaMecanico = "";
    },

    editItem(item) {
      this.editedIndex = true;
      this.drawer = true;
      this.id = item._id;
      this.nombreMecanico = item.nombreMecanico;
      this.categoriaMecanico = item.categoria;
    },

    mostrarNuevo() {
      this.drawer = true;
      this.editedIndex = false;
      this.limpiar();
    },
    ocultarNuevo() {},
    salir() {
      this.drawer = false;
    },

    listar() {
      let me = this;
      axios
        .get("/mecanico/list")
        .then(function (response) {
          me.mecanicos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    guardar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      if (this.editedIndex == true) {
        axios
          .put(
            "/mecanico/update",
            {
              _id: this.id,
              nombreMecanico: this.nombreMecanico,
              categoria: this.categoriaMecanico,
            },
            configuracion
          )
          .then(function () {
            me.drawer = false;
            //  me.dialog=false

            me.listar();
            me.addSuccessNotification("Datos editados correctamente");
            me.editedIndex = false;
          })
          .catch(function (error) {
            console.log(error);
            me.addErrorNotification();
          });
      } else {
        axios
          .post(
            "/mecanico/add",
            {
              nombreMecanico: this.nombreMecanico,
              categoria: this.categoriaMecanico,
              pin: Math.floor(100000 + Math.random() * 900000),
            },
            configuracion
          )
          .then(function () {
            me.drawer = false;
            me.listar();
            me.addSuccessNotification("Datos ingresados correctamente");
          })
          .catch(function (error) {
            console.log(error);
            me.addErrorNotification();
          });
      }
    },

    mostrarDialogBaja(item) {
      this.dialogMecanicoBaja = true;
      this.id = item;
    },

    mostrarDialogAlta(item) {
      this.dialogMecanicoAlta = true;
      this.id = item;
    },

    deactivate() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      axios
        .put(
          "/mecanico/inactivar",
          {
            _id: this.id,
          },
          configuracion
        )
        .then(function () {
          me.dialogMecanicoBaja = false;
          me.listar();
          me.addSuccessNotification("Datos actualizados correctamente");
        })
        .catch(function (error) {
          console.log(error);
          me.addErrorNotification();
        });
    },

    activate() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };

      axios
        .put(
          "/mecanico/activate",
          {
            _id: this.id,
          },
          configuracion
        )
        .then(function () {
          me.dialogMecanicoAlta = false;
          me.listar();
          me.addSuccessNotification("Datos actualizados correctamente");
        })
        .catch(function (error) {
          console.log(error);
          me.addErrorNotification();
        });
    },

    selectMecanicos() {
      let me = this;
      let mecaniccArray = [];
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("/mecanico/listActivos", configuracion)
        .then((response) => {
          mecaniccArray = response.data;
          mecaniccArray.map(function (x) {
            me.mecanicosActivos.push({ text: x.nombreMecanico, value: x._id });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.Revision {
  border-left: 4px solid #3cd1c2;
}

.sin_asignar {
  border-left: 4px solid orange;
}

.Pendiente {
  border-left: 4px solid black;
}

.Cancelado {
  border-left: 4px solid #f83e70;
}

.app_more {
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
}

.app_more:hover {
  text-decoration: underline;
}

.app_more,
.app_more:active {
  color: #3cd1c2;
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid rgb(123, 90, 243);
  border-top-color: #fff;
  position: absolute;
  bottom: 0;
  left: calc(50%-20px);
  animation: spinner-animation 1.2s linear infinite;
}

.tebs.Revision {
  border-left: 4px solid orange;
}

.tebs.Finalizado {
  border-left: 4px solid rgb(123, 90, 243);
}

.v-chip.Activo {
  background: #f83e70;
  color: black;
}

#chips-container .v-chip.activo {
  color: white;
}

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
