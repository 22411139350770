<template>
  <v-container fluid>
    <div class="tables-basic">
      <br />
      <h1 class="page-title mt-16 mb-6">Ubicaciones</h1>
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
              <v-row>
                <v-dialog v-model="dialogNuevaUbicacion" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="$store.state.usuario.rol !== 'lectura_mtto'"
                      v-bind="attrs"
                      v-on="on"
                      rounded
                      color="primary"
                      small
                      dark
                      >Nueva Ubicación</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              :items="empresas"
                              v-model="empresa"
                              label="Empresas"
                            ></v-autocomplete>
                            <div v-if="submited && !$v.empresa.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo Empresa es requerido
                              </v-alert>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="ubicacion"
                              label="Ubicacion"
                            ></v-text-field>
                            <div v-if="submited && !$v.ubicacion.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo Ubicacion es requerido
                              </v-alert>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="direccion"
                              label="Direccion"
                            ></v-text-field>
                            <div v-if="submited && !$v.direccion.required">
                              <v-alert
                                dense
                                v-model="alert"
                                dismissible
                                color="red"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-alert-octagon"
                              >
                                <strong>Error</strong> Campo direccion es requerido
                              </v-alert>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="cerrarModalUbicacion()">
                        Cerrar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="guardar()">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table dense :headers="headers" :items="ubicaciones" :search="search">
              <template
                v-if="$store.state.usuario.rol !== 'lectura_mtto'"
                v-slot:[`item.action`]="{ item }"
              >
                <v-icon @click="editItem(item)" small class="mr-2"
                  >mdi-border-color</v-icon
                >
                <template>
                  <v-icon class="mr-2">mdi-delete</v-icon>
                </template>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  validations: {
    empresa: {
      required,
    },

    ubicacion: {
      required,
    },
    direccion: {
      required,
    },
  },
  data() {
    return {
      headers: [
        { text: "Actions", value: "action", sortable: false },
        { text: "Empresa", value: "empresa.razonSocial", sortable: true },
        { text: "Nombre Comercial", value: "empresa.nombreComercial", sortable: true },
        { text: "Ubicacion", value: "ubicacion", sortable: true },
        { text: "Dirección", value: "direccion", sortable: true },
      ],
      empresas: [],
      empresa: "",
      ubicacion: "",
      direccion: "",
      search: "",
      dialogNuevaUbicacion: false,
      ubicaciones: [],
      editedIndex: -1,
      submited: "",
      alert: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Ubicacion" : "Editar Ubicacion";
    },
  },
  created() {
    this.listar();
    this.selectEmpresas();
  },
  methods: {
    async listar() {
      let me = this;
      let empresaUsuario = this.$store.state.usuario.personal.empresa;
      let usuario = this.$store.state.usuario.rol;
      this.ubicaciones = [];
      await axios
        .get("/ubicacion/list")
        .then(function (response) {
          usuario == "capturista"
            ? response.data.forEach((e) => {
                if (e.empresa._id == empresaUsuario) {
                  me.ubicaciones.push(e);
                }
              })
            : (me.ubicaciones = response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
      if (usuario == "capturista") {
        this.empresas.push({
          text: me.ubicaciones[0].empresa.nombreComercial,
          value: me.ubicaciones[0].empresa._id,
        });
      }
    },
    limpiar() {
      (this.empresa = ""),
        (this.ubicacion = ""),
        (this.direccion = ""),
        (this.alert = false);
      this.editedIndex = -1;
    },
    guardar() {
      let me = this;
      if (this.editedIndex > -1) {
        axios
          .put("/ubicacion/update", {
            _id: this._id,
            empresa: this.empresa,
            ubicacion: this.ubicacion,
            direccion: this.direccion,
          })
          .then(function (response) {
            console.log(response);
            me.listar();
            me.dialogNuevaUbicacion = false;
            me.addSuccessNotification("Ubicacion actualizada");
            me.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.submited = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          me.alert = true;
          me.addErrorNotification();
          return false;
        }
        axios
          .post("/ubicacion/add", {
            empresa: this.empresa,
            ubicacion: this.ubicacion,
            direccion: this.direccion,
          })
          .then(function (response) {
            console.log(response);
            me.listar();
            me.dialogNuevaUbicacion = false;
            me.addSuccessNotification("Ubicacion agregada");
            me.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    editItem(item) {
      this._id = item._id;
      this.empresa = item.empresa._id;
      this.ubicacion = item.ubicacion;
      this.direccion = item.direccion;
      this.editedIndex = 1;

      this.dialogNuevaUbicacion = true;
    },
    selectEmpresas() {
      let me = this;
      let empresaArray = [];
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      if (this.$store.state.usuario.rol !== "capturista") {
        axios
          .get("/empresa/list", configuracion)
          .then((response) => {
            empresaArray = response.data;
            empresaArray.map(function (x) {
              me.empresas.push({ text: x.nombreComercial, value: x._id });
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    addSuccessNotification(mensaje) {
      this.$toast.success(mensaje, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    addErrorNotification() {
      this.$toast.error("Error, verifique los campos ingresados", {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    cerrarModalUbicacion() {
      this.dialogNuevaUbicacion = false;
      this.limpiar();
      this.editedIndex = -1;
    },
  },
};
</script>
